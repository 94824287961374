import React from 'react';
import './Services.css';
import icon1 from '../../../images/services/icon/service-shape.png';
import icon2 from '../../../images/services/icon/blue-flexible-cms.png';
import icon3 from '../../../images/services/icon/blue-page-speed.png';
import icon4 from '../../../images/services/icon/blue-ecommerce.png';
import Service from './Service';

const servicesData =[
    {
        id:1,
        name: 'Dynamic website development',
        description: 'Transform your online presence with dynamic, responsive websites that are visually stunning and easy to manage. From customization to seamless maintenance, we handle everything, including advanced animations with JavaScript and CSS.',
        icon: icon1
    },
    {
        id:2,
        name: 'Web application development',
        description: 'Empower your business with bespoke web applications tailored to your needs. Whether it’s accounting systems, school management software, CRM, or inventory solutions—we build powerful applications for any purpose.',
        icon: icon2
    },
    {
        id:3,
        name: 'Page Speed Optimization & SEO',
        description: "Enhance your website's performance with lightning-fast loading speeds and optimized page sizes. Our expertise in Google Accelerated Mobile Pages (AMP) and SEO ensures maximum visibility and engagement.",
        icon: icon3
    },
    {
        id:4,
        name: 'eCommerce Websites Development',
        description: 'Boost your sales with a fully customized eCommerce website. From grocery stores to fashion boutiques and multi-vendor platforms, we create solutions that drive growth and set you apart in the digital marketplace.',
        icon: icon4
    }
]

function Services() {
    return (
        <div className="service-container">
            <div className="text-center pt-5 w-50 m-auto">
                <h4 style={{fontWeight:'300', marginBottom:'10px'}}>SERVICES THAT WE PROVIDE DEDICATEDLY</h4>
                <h2 style={{fontSize:''}}>OUR SERVICES</h2>
            </div>

            <div className="row service">
                {
                    servicesData.map(data =><Service data={data} key={data.id}></Service>)
                }
            </div>
        </div>
    )
}

export default Services
