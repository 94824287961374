import React from 'react';
import Navbar from './Toolsbar/Navbar/Navbar';
import Toolsbar from './Toolsbar/Toolsbar';
import './Home.css';
import Slider from './Slider/Slider';
import Features from './Features/Features';
import About from './About/About';
import Services from './Services/Services';
import SkillSet from './SkillSet/SkillSet';
import Team from './Team/Team';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Blogs from './Blogs/Blogs';
import ProjectSummary from './ProjectSummary/ProjectSummary';
import Projects from './Projects/Projects';

function Home() {
    return (
        <div>
            <div className="nav-header">
                <Toolsbar></Toolsbar>
                <Navbar></Navbar>
                <Slider></Slider>
            </div>
            <Features></Features>
            <div id="about"><About></About></div>
            <div id="services"><Services></Services></div>
            <div id="projects"><Projects></Projects></div>
            <div id="project-summary"><ProjectSummary></ProjectSummary></div>
            <div id="skillset"><SkillSet></SkillSet></div>
            <div id="team"><Team></Team></div>
            <div id="contact"><Contact></Contact></div>
            <Footer></Footer>
        </div>
    );
}

export default Home;
