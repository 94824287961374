import React from 'react';
import './SkillSet.css';

function SkillSet() {
    return (
        <div className="rs-video style2 pt-5 mt-4 pb-5 mb-4">
            <div className="container">
                <div className="video-skill">
                    <div className="row margin-0">
                        <div className="col-lg-6 padding-0 md-mb-50">
                            <div className="skillsets-part">
                                <div className="sec-title4 mb-40">
                                    <span className="sub-title white-color pb-15">Our Expertise</span>
                                    <h2 className="title white-color">A Team of Experts Delivering Customized IT Solutions</h2>
                                </div>
                                <p className="white-color mb-4">At GTech Solutions, we leverage diverse skillsets to provide tailored IT services that drive success. From software development to cloud integration, our team is dedicated to delivering innovative solutions to meet your unique needs.</p>
                                <div className="cl-skill-bar style2 solutions mt-5 mb-5">
                                    <span className="skillbar-title">Software Development</span>
                                    <div className="skillbar" data-percent="90">
                                        <p className="skillbar-bar" style={{ width: '90%' }}></p>
                                        <span className="skill-bar-percent">90%</span>
                                    </div>
                                    <span className="skillbar-title">Cloud Services</span>
                                    <div className="skillbar" data-percent="85">
                                        <p className="skillbar-bar" style={{ width: '85%' }}></p>
                                        <span className="skill-bar-percent">85%</span>
                                    </div>
                                    <span className="skillbar-title">Web Development</span>
                                    <div className="skillbar" data-percent="95">
                                        <p className="skillbar-bar" style={{ width: '95%' }}></p>
                                        <span className="skill-bar-percent">95%</span>
                                    </div>
                                </div>
                                <div className="btn-part mt-55">
                                    <a className="readon2 contact solutions" href="contact.html">Get Started with Us Today</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 padding-0">
                            <div className="video-wrap">
                                <div className="video-btn primary text-center">
                                    <a className="popup-videos" href="https://www.youtube.com/watch?v=YLN1Argi7ik">
                                        <i className="fa fa-play"></i>
                                    </a>
                                    <p className="white-color mt-3">Watch our journey and learn how we transform ideas into reality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkillSet;
