import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './Navbar.css';
import logo from '../../../../images/logo.png';

function Navbar() {
    return (
        <div className="Navbar">
            <div className="row d-flex align-items-center" style={{ margin: 'auto' }}>
                <div className="col-md-4 toolsbar">
                    <img src={logo} alt="logo" />
                </div>
                <div className="col-md-7 navbar-list">
                    <ul className="d-flex justify-content-around">
                        <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
                        <li><Link to="about" smooth={true} duration={500}>About</Link></li>
                        <li><Link to="services" smooth={true} duration={500}>Services</Link></li>
                        <li><Link to="projects" smooth={true} duration={500}>Projects</Link></li>
                        <li><Link to="team" smooth={true} duration={500}>Team</Link></li>
                        {/* <li><Link to="blog" smooth={true} duration={500}>Blog</Link></li> */}
                        <li><Link to="contact" smooth={true} duration={500}>Contact</Link></li>
                    </ul>
                </div>
                <div className="col-md-1 toolsbar">
                    <div className="expand-btn-inner dots">
                        <ul className="d-flex justify-content-around">
                            <li>
                                <a id="nav-expander" className="humburger nav-expander" href="#">
                                    <span className="dot1"></span>
                                    <span className="dot2"></span>
                                    <span className="dot3"></span>
                                    <span className="dot4"></span>
                                    <span className="dot5"></span>
                                    <span className="dot6"></span>
                                    <span className="dot7"></span>
                                    <span className="dot8"></span>
                                    <span className="dot9"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
