import React from 'react';
import './About.css';
import about1 from '../../../images/about/solutions/1.jpg'
import about2 from '../../../images/about/solutions/2.png'
import about3 from '../../../images/about/solutions/3.png'

function About() {
    return (
        <div id="rs-about" class="rs-about style10 gray-bg5 p-5">
                <div class="" style={{width: '90%', margin: 'auto'}}>
                    <div class="row">
                        <div class="col-lg-6 pr-70 md-pr-15 md-mb-50">
                            <div class="sec-title4 mb-30">
                                <span class="sub-title new pb-10">About Us</span>
                                <h2 class="title pb-20">Crafting Innovative IT Solutions for a Digital Future</h2>
                                <p class="margin-0">At GTech Solutions, we transform ideas into reality with creative, cutting-edge technology solutions tailored to your business needs. Our team is dedicated to delivering excellence, driving innovation, and fostering growth through bespoke software and app development.</p>
                            </div>
                            <div id="accordion" class="accordion">
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link" data-toggle="collapse" href="#collapseOne">Introduction</a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                        <div class="card-body">At GTech Solutions, we don't just follow trends—we set them. Our passion for technology drives us to create dynamic, tailored IT solutions that empower your business to excel in a digital world. With us, you get more than just a service; you get a partner committed to your success, ready to turn your boldest ideas into reality.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">Our Mission</a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                        <div class="card-body">Our mission is to empower businesses with cutting-edge technology solutions that enhance performance, optimize processes, and elevate user experiences. We aim to build lasting partnerships by consistently delivering high-quality services that exceed expectations.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="images-part">
                                    <img src={about1} alt="Images"/>
                                </div>
                                <div class="rs-animations">
                                    <div class="spinner dot">
                                        <img class="scale" src={about2} alt="Images"/>
                                    </div>
                                    <div class="spinner ball">
                                        <img class="dance2" src={about3} alt="Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}

export default About
