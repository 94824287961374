import React from 'react';
import Feature from './Feature';
import './Features.css';
import icon2 from '../../../images/whychooseus/icon/ecommerce.png'
import icon1 from '../../../images/whychooseus/icon/flexible-cms.png'
import icon3 from '../../../images/whychooseus/icon/page-speed.png'
import icon4 from '../../../images/whychooseus/icon/responsive-design.png'

const featureData = [
    {
        id:1,
        icon: icon4,
        title: 'Responsive Design',
        description: 'Our designs adapt flawlessly to any device, ensuring a stunning, user-friendly experience across all screens—because your website should shine everywhere.'
    },
    {
        id:2,
        icon: icon3,
        title: 'Blazing Fast Performance',
        description: 'Experience lightning-fast page loads and optimized performance that keeps your visitors engaged. Speed is the new currency of the web.'
    },
    {
        id:3,
        icon: icon2,
        title: 'Powerful eCommerce Solutions',
        description: 'Create an eye-catching eCommerce site with custom features that drive sales and growth. Let your business stand out and succeed online.'
    },
    {
        id:4,
        icon: icon1,
        title: 'Dynamic Content Management (CMS)',
        description: 'Take full control with a flexible, user-friendly CMS that allows you to update and customize your content effortlessly. Your vision, your way.'
    }
]

function Features() {
    return (
        <div className="features-container   m-auto">
            <div className="text-center pt-5">
                <h4 style={{fontWeight:'300', marginBottom:'10px'}}>WHY CHOOSE US</h4>
                <h2 style={{fontSize:'45px'}}>FEATURES</h2>
            </div>
            <div className="row  m-auto">
                {
                    featureData.map(data =><Feature data={data} key={data.id}></Feature>)
                }
            </div>

            <div className="text-center mt-5">
            <p style={{ fontWeight: '600' }}>
                Increase your business success with technology. 
                <a 
                    href="https://wa.me/+918830415943" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    Let’s get started
                </a>
                </p>
            </div>
        </div>
    )
}

export default Features
