import React from 'react'
import './ProjectSummary.css';

function ProjectSummary() {
    return (
        <div className="projectSummary-container">
            <div className="" style={{ width: '90%', margin: 'auto' }}>
                <h4 className="text-white text-center" style={{ fontWeight: '300', paddingTop: '40px' }}>WHY CHOOSE GTECH SOLUTIONS?</h4>
            </div>
            <div className="row p-5">
                <div className="col-md-3 text-center">
                    <h2 className="text-white">10+</h2>
                    <h4 className="text-white" style={{fontWeight:'400'}}>SUCCESSFUL PROJECTS</h4>
                </div>
                <div className="col-md-3 text-center">
                    <h2 className="text-white">100%</h2>
                    <h4 className="text-white" style={{fontWeight:'400'}}>CLIENT SATISFACTION</h4>
                </div>
                <div className="col-md-3 text-center">
                    <h2 className="text-white">NEW</h2>
                    <h4 className="text-white" style={{fontWeight:'400'}}>FRESH IDEAS</h4>
                </div>
                <div className="col-md-3 text-center">
                    <h2 className="text-white">Value-Driven</h2>
                    <h4 className="text-white" style={{fontWeight: '400'}}>Affordable Excellence</h4>
                </div>
            </div>
        </div>
    )
}

export default ProjectSummary
